<template>
  <section id="sobre-nos" class="section">
    <div class="big-clientes">
      <h1 class="text-client">Nossos clientes</h1>
      <div class="quadro-clientes">
            <img src="../assets/mipe.png" alt="Mipe" class="cliente-logo">
            <img src="../assets/speed.png" alt="Speed" class="cliente-logo">
            <img src="../assets/proativa.png" alt="Pro Ativa" class="cliente-logo">
            <img src="../assets/cmc.png" alt="Cmc" class="cliente-logo">
            <img src="../assets/jf.png" alt="FJ" class="cliente-logo">
            <img src="../assets/esvj.png" alt="ESVJ" class="cliente-logo2">
        </div>
    </div>

    <div class="sobre">
      <div style="width: 100%;">
        <h1 class="text-client">Sobre nós</h1>
      </div>
      <div class="big-box">
        
        <img :src="require('@/assets/inovacao.png')"  class="img-about"/>
        <h1>Inovação</h1>
        <h2>Investimos continuamente em tecnologia e capacitação.</h2>
      </div>
      <div class="big-box">
        <img src="../assets/compromisso.png" class="img-abount"/>
        <h1>Compromisso</h1>
        <h2>
          Estamos comprometidos com a satisfação e o sucesso de nossos clientes.
        </h2>
      </div>
      <div class="big-box">
        <img src="../assets/qualidade.png" class="img-abount2"/>
        <h1>Qualidade</h1>
        <h2>Buscamos a excelência em cada serviço prestado.</h2>
      </div>
      <div class="big-box">
        <img src="../assets/seguranca.png" class="img-abount2"/>
        <h1>Segurança</h1>
        <h2>Priorizamos a segurança em todos os processos e inspeções.</h2>
      </div>
      <div class="big-box">
        <img src="../assets/visao.png" class="img-abount"/>
        <h1>Nossa Visão</h1>
        <h2>
          Ser reconhecidos como a principal empresa de inspeção de soldas no
          mercado, valorizando a integridade, a qualidade e a satisfação do
          cliente em todos os nossos serviços.
        </h2>
      </div>
      <div class="big-box">
        <img src="../assets/missao.png" class="img-abount"/>
        <h1>Nossa Missão</h1>
        <h2>
          Comprometidos com a excelência, buscamos fornecer serviços de inspeção
          e qualificação que garantam a segurança e qualidade dos projetos de
          nossos clientes, promovendo a inovação e o desenvolvimento contínuo.
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppAbout",
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800;500&display=swap");

.text-client{
  font-size: 25px;
}

.quadro-clientes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.cliente-logo {
    height: 200px;
    width: 200px;
    object-fit: contain;
    padding: 10px;
}

.cliente-logo2 {
    height: 180px;
    width: 180px;
    margin-left: 10px;
    object-fit: contain;
    padding: 10px;
}

.img-abount{
  width: 200px;
  height: 200px;
}

.img-abount2{
  width: 150px;
  height: 150px;
}


.section {
  height: 300vh;
  width: 100%;
  background-color: #181717;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 95px 120px;
}

.sobre{
  width: 100%;
  background-color: #181717;
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 95px 120px;
}

.box {
  width: 226px;
  height: 146px;
  text-align: left;
}

.big-box {
  width: 573px;
  height: 300px;
  text-align: center;
}

.big-clientes{
  width: 800px;
  height: 160px;
  text-align: center;
}

.big-clientes h1{
  text-align: center;
}

h1 {
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}

h2 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #616161;
  margin: 0;
}

.big-box h1 {
  text-align: center;
}

@media (max-width: 1270px) {
.big-box {
  width: 350px;
}
}

@media (max-width: 720px) {
  .section{
  height: 400vh;
}
}

@media (max-height: 680px) and (min-width: 720px) {
  .section{
  height: 350vh;
}
}

@media (max-height: 820px) and (max-width: 720px) {
  .section{
  height: 450vh;
}
}

@media (max-height: 700px) and (max-width: 720px) {
  .section{
  height: 500vh;
}
}


@media (max-width: 900px) {
  .quadro-clientes {
    grid-template-columns: repeat(2, 1fr);
  }

  .cliente-logo {
    height: 120px;
    width: 120px;
}

.cliente-logo2 {
    height: 110px;
    width: 110px;
}


.big-box {
  width: 300px;
}

.section{
  width: 100%;
}

.sobre{
  width: 100%;
}

.big-clientes{
  width: 300px;
  margin-top: 100px;
}

}

</style>
